












import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  MilesAndMoreClaimType
} from './MilesAndMoreClaim.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<MilesAndMoreClaim>({
  name: 'MilesAndMoreClaim'
})
export class MilesAndMoreClaim extends Vue {
  @Prop({ type: Number, required: true })
  public readonly pointsLimit!: number

  @Prop({ type: String, required: true })
  public readonly type!: MilesAndMoreClaimType

  public get contents (): Array<string> {
    switch (this.type) {
      case MilesAndMoreClaimType.PointsLimitSet:
        return [
          this.$t('front.checkout.views.CartView.summary.benefits.limit.claim1', { pointsLimit: this.pointsLimit }).toString(),
          this.$t('front.checkout.views.CartView.summary.benefits.limit.claim2').toString()
        ]
      case MilesAndMoreClaimType.PointsLimitNear:
        return [
          this.$t('front.checkout.views.CartView.summary.benefits.limit.claim3').toString(),
          this.$t('front.checkout.views.CartView.summary.benefits.limit.claim1', { pointsLimit: this.pointsLimit }).toString()
        ]
    }
  }
}

export default MilesAndMoreClaim
