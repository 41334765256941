





































import { Component, Mixins } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ILoyaltyService, LoyaltyServiceType } from '../../../loyalty/contracts/services'
import { BenefitProgram, LinkData } from '../../../loyalty/contracts/programs'
import { benefitLogo, MILES_PER_PLN } from '../../../loyalty/shared'
import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'
import { ISiteService, SiteServiceType, TabOption } from '../../../../contexts'
import {
  BenefitPropertyName
} from '../../../loyalty/organisms/BenefitPrograms/BenefitPrograms.config'
import {
  CartBenefitsAddon
} from './CartBenefits.contracts'
import MilesAndMoreClaim
  from './addons/MilesAndMoreClaim/MilesAndMoreClaim.vue'
import {
  MilesAndMoreClaimType
} from './addons/MilesAndMoreClaim/MilesAndMoreClaim.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<CartBenefits>({
  name: 'CartBenefits',
  async created () {
    const loyalty = await this.loyaltyService.fetch()
    this.programs = loyalty.getCurrentPrograms()
    this.$store.commit('shared/setLoyalty', loyalty.getPayload())

    this.composeProgramsLink()
    this.$emit('loyaltyPrograms', this.programs)
  }
})
export class CartBenefits extends Mixins<IBaseCart>(BaseCartMixin) {
  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public programs: BenefitProgram[] = []
  public benefitsLinks: Record<string, LinkData> | null = null

  public get hasPrograms (): boolean {
    if (!this.cart) {
      return false
    }

    return !!this.programs.length
  }

  public get isMMLoyaltyLimitSet (): boolean {
    const loyalty = this.$store.getters['shared/getLoyalty']
    if (typeof loyalty.milesAndMorePoints === 'undefined' || typeof loyalty.milesAndMorePointsLimit === 'undefined') {
      return false
    }
    return loyalty.milesAndMorePoints >= loyalty.milesAndMorePointsLimit
  }

  public get isMMLoyaltyLimitNear (): boolean {
    if (!this.cart) {
      return false
    }

    const loyalty = this.$store.getters['shared/getLoyalty']
    const count = Math.floor(MILES_PER_PLN * this.cart.total.value)

    if (typeof loyalty.milesAndMorePoints === 'undefined' || typeof loyalty.milesAndMorePointsLimit === 'undefined') {
      return false
    }

    return loyalty.milesAndMorePoints + count >= loyalty.milesAndMorePointsLimit
  }

  public getLabel (program: BenefitProgram): string {
    const label = `front.checkout.views.CartView.summary.benefits.${program}`

    switch (program) {
      case BenefitProgram.FriendsAndFamily:
      case BenefitProgram.Kameleon:
        return this.$t(label).toString()
      case BenefitProgram.MilesAndMore: {
        if (!this.hasFaF) {
          return this.$t(label).toString()
        }

        const labelWithFaF = `${label}FaF`
        const translation = this.$t(labelWithFaF)

        if (translation !== labelWithFaF) {
          return translation.toString()
        }

        return this.$t(label).toString()
      }
    }
  }

  public getLogo (program: BenefitProgram): string {
    return benefitLogo[program]
  }

  public isClaimVisible (program: BenefitProgram): boolean {
    switch (program) {
      case BenefitProgram.MilesAndMore:
        return this.isMMLoyaltyLimitSet || this.isMMLoyaltyLimitNear
      default:
        return false
    }
  }

  public getAddonComponent (program: BenefitProgram): CartBenefitsAddon {
    const loyalty = this.$store.getters['shared/getLoyalty']

    switch (program) {
      case BenefitProgram.MilesAndMore:
        return {
          component: MilesAndMoreClaim,
          props: {
            pointsLimit: loyalty.milesAndMorePointsLimit,
            type: this.isMMLoyaltyLimitSet
              ? MilesAndMoreClaimType.PointsLimitSet
              : MilesAndMoreClaimType.PointsLimitNear
          }
        }
      default:
        return { component: null }
    }
  }

  public getValue (program: BenefitProgram): string {
    const loyalty = this.$store.getters['shared/getLoyalty']

    switch (program) {
      case BenefitProgram.FriendsAndFamily:
        return '31%'
      case BenefitProgram.Kameleon:
        return '10%'
      case BenefitProgram.MilesAndMore: {
        if (!this.cart) {
          return ''
        }

        const candidateCount = Math.floor(MILES_PER_PLN * (this.cart.total.value - (this.cart.selectedShippingMethod ? this.cart.selectedShippingMethod.price.value : 0)))
        const count = this.isMMLoyaltyLimitSet
          ? 0
          : this.isMMLoyaltyLimitNear
            ? typeof loyalty.milesAndMorePointsLimit === 'undefined' || typeof loyalty.milesAndMorePoints === 'undefined'
              ? candidateCount
              : loyalty.milesAndMorePointsLimit - loyalty.milesAndMorePoints
            : candidateCount
        return this.$tc('front.checkout.views.CartView.summary.benefits.count', count, { count })
      }
    }
  }

  public getProgramLink (program: string): LinkData | null {
    if (!this.benefitsLinks) {
      return null
    }

    return this.benefitsLinks[program]
  }

  protected get hasFaF (): boolean {
    return this.programs.includes(BenefitProgram.FriendsAndFamily)
  }

  protected composeProgramsLink (): void {
    if (!this.siteService) {
      return
    }

    const properties = this.siteService.getActiveSite().properties
    for (const [key, value] of Object.entries(properties)) {
      if (key === BenefitPropertyName.Kameleon) {
        this.benefitsLinks = {
          ...this.benefitsLinks,
          [key]: {
            tabTarget: TabOption.Blank,
            target: value as string,
            label: key,
            title: key
          }
        }
      } else if (key === BenefitPropertyName.MilesMore) {
        const label = 'milesAndMore'
        this.benefitsLinks = {
          ...this.benefitsLinks,
          [label]: {
            tabTarget: TabOption.Blank,
            target: value as string,
            label: label,
            title: key
          }
        }
      }
    }
  }
}

export default CartBenefits
